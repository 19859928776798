import { createTheme } from '@mui/material/styles'
import WebFont from '../webfontloader'

WebFont.load({
  google: {
    families: [
      'Outfit:100,300,400,500,700',
      'Playfair Display:400,500,700,800&display=swap',
    ],
  },
})

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1200,
      lg: 1280,
      xl: 1920,
    },
  },
})

theme = createTheme(theme, {
  palette: {
    primary: {
      main: '#03341D',
    },
    secondary: {
      main: '#EBF6FF',
    },
    error: {
      main: '#891A1A',
    },
    divider: '#2B2E31',
    trustBlue: {
      main: '#EBF6FF',
    },
    moneyGreen: {
      main: '#03341D',
    },
    errorRed: {
      main: '#891A1A',
    },
    exclamationRed: {
      main: '#540000',
    },
    softGold: {
      main: '#FFEBD9',
    },
    green0: {
      main: '#EBF1F1',
    },
    green1: {
      main: '#E1EEEF',
    },
    green2: {
      main: '#C9DADB',
    },
    green3: {
      main: '#7B9488',
    },
    green4: {
      main: '#136D42',
    },
    green5: {
      main: '#004021',
    },
    gray1: {
      main: '#F7F8F8',
    },
    gray2: {
      main: '#E9EAEB',
    },
    gray3: {
      main: '#CBCDCF',
    },
    gray4: {
      main: '#B6BABE',
    },
    gray5: {
      main: '#82878C',
    },
    gray6: {
      main: '#555B60',
    },
    gray7: {
      main: '#45494D',
    },
    gray8: {
      main: '#2B2E31',
    },
    white: {
      main: '#FFFFFF',
    },
    white5: {
      main: '#FFFFFF50',
    },

    progressComplete: {
      main: '#C9DADB',
    },
    progressCompleteText: {
      main: '#82878C',
    },
    progressCurrent: {
      main: '#03341D',
    },
    progressCurrentText: {
      main: '#555B60',
    },
    progressFuture: {
      main: '#7B9488',
    },
    progressFutureText: {
      main: '#555B60',
    },
  },
})

theme.shadows[8] = '0px 8px 24px rgba(69, 73, 77, 0.08)'

theme = createTheme(theme, {
  typography: {
    fontFamily: 'Outfit',
    h1: {
      fontFamily: 'Playfair Display',
      fontSize: '4rem',
      lineHeight: '5rem',
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.25rem',
        lineHeight: '3rem',
      },
    },
    h2: {
      fontFamily: 'Playfair Display',
      fontSize: '3rem',
      lineHeight: '3.75rem',
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
        lineHeight: '2.5rem',
      },
    },
    h2m: {
      fontFamily: 'Playfair Display',
      fontWeight: 800,
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
    h3: {
      fontFamily: 'Playfair Display',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },
    },
    h3m: {
      fontFamily: 'Playfair Display',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Outfit',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },
    },
    p1: {
      fontFamily: 'Outfit',
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },
    p2: {
      fontFamily: 'Outfit',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    p3: {
      fontFamily: 'Outfit',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    p4: {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    p5: {
      fontFamily: 'Outfit',
      fontSize: '0.625rem',
      lineHeight: '120%',
      fontWeight: 400,
    },
    p6: {
      fontFamily: 'Outfit',
      fontSize: '0.5rem',
      lineHeight: '120%',
      fontWeight: 400,
    },
    l1: {
      fontFamily: 'Outfit',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 300,
    },
    c1: {
      fontFamily: 'Outfit',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
      },
    },
    cta: {
      fontFamily: 'Outfit',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    cta2: {
      fontFamily: 'Outfit',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },

    largeBadge: {
      fontFamily: 'Playfair Display',
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontweight: 700,
      textAlign: 'center',
    },
    mediumBadge: {
      fontFamily: 'Playfair Display',
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: 700,
      textAlign: 'center',
    },
    smallBadge: {
      fontFamily: 'Playfair Display',
      fontSize: '0.8rem',
      lineHeight: '0.875rem',
      fontWeight: 700,
      textAlign: 'center',
    },
    miniBadge: {
      fontFamily: 'Playfair Display',
      fontSize: '0.563rem',
      lineHeight: '0.625rem',
      fontWeight: 700,
      textAlign: 'center',
    },
    // Variants used by default MUI components
    // Identical to CTA
    button: {
      fontFamily: 'Outfit',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 500,
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },

    body1: {
      fontFamily: 'Outfit',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },

    // Disabled variants
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body2: undefined,
    caption: undefined,
    overline: undefined,
  },
})

const mobileButtonStyles = {
  width: '100%',
  paddingTop: '8px',
  paddingBottom: '8px',
  ...(theme.typography.cta as any),
}

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'p1',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'primary',
        size: 'large',
      },
      styleOverrides: {
        root: {
          border: `2px solid ${theme.palette.white.main}10`,
          transition:
            'border-color 0.1s linear, outline 0.1s linear, color 0.1s linear, background-color 0.1s linear, box-shadow 0.1s ease',
          borderRadius: '50rem',

          // Default colors
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.white.main,

          '&:hover': {
            backgroundColor: theme.palette.green3.main,
          },

          '&:active': {
            color: theme.palette.white,
            backgroundColor: theme.palette.gray8.main,
          },

          '&.Mui-disabled': {
            color: theme.palette.gray2.main,
            backgroundColor: theme.palette.gray5.main,
          },
          '&:focus': {
            boxShadow: `0px 8px 24px rgba(0, 0, 0, 0.25)`,
          },
        },
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            backgroundColor: 'transparent',
            color: theme.palette.green4.main,
            fontWeight: 'normal!important',
            padding: '0px!important',
            width: 'auto!important',
            height: 'auto!important',
            letterSpacing: 'unset',

            border: 'none',
            transition: 'none',
            borderRadius: 'none',

            '&:hover': {
              backgroundColor: 'transparent',
            },

            '&:active': {
              backgroundColor: 'transparent',
            },

            '&.Mui-disabled': {
              backgroundColor: 'transparent',
            },
            '&:focus': {
              boxShadow: `unset`,
            },
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,

            '&:hover': {
              backgroundColor: theme.palette.green3.main,
            },

            '&:active': {
              color: theme.palette.white,
              backgroundColor: theme.palette.gray8.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray2.main,
              backgroundColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: theme.palette.primary.main,
            backgroundColor: `${theme.palette.white.main}50`,
            border: `2px solid ${theme.palette.primary.main}`,
            '&:hover': {
              backgroundColor: theme.palette.green1.main,
            },

            '&:active': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.green3.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray5.main,
              backgroundColor: theme.palette.gray2.main,
              borderColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { variant: 'error' },
          style: {
            color: theme.palette.errorRed.main,
            backgroundColor: `${theme.palette.white.main}50`,
            border: `2px solid ${theme.palette.errorRed.main}`,
            '&:hover': {
              backgroundColor: `${theme.palette.errorRed.main}25`,
            },

            '&:active': {
              color: theme.palette.errorRed.main,
              backgroundColor: `${theme.palette.errorRed.main}50`,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray5.main,
              backgroundColor: theme.palette.gray2.main,
              borderColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            color: theme.palette.gray8.main,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: theme.palette.gray1.main,
            },

            '&:active': {
              backgroundColor: theme.palette.gray4.main,
            },

            '&.Mui-disabled': {
              color: theme.palette.gray5.main,
              backgroundColor: theme.palette.gray2.main,
              borderColor: theme.palette.gray5.main,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            ...(theme.typography.cta as any),
            width: '100%',
            paddingTop: '20px',
            paddingBottom: '20px',
            borderRadius: '50rem',

            [theme.breakpoints.down('sm')]: mobileButtonStyles,
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '8px 32px',
            borderRadius: '50rem',

            [theme.breakpoints.down('sm')]: mobileButtonStyles,
          },
        },
        {
          props: { size: 'largeForm' },
          style: {
            ...(theme.typography.cta2 as any),
            width: 'inherit',
            padding: '16px 32px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.cta2 as any),
              padding: '16px 32px',
            },
          },
        },
        {
          props: { size: 'smallForm' },
          style: {
            fontWeight: 'unset',
            ...(theme.typography.cta2 as any),
            width: 'inherit',
            padding: '8px 32px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.cta2 as any),
              padding: '8px 32px',
            },
          },
        },
        {
          props: { size: 'mini' },
          style: {
            fontWeight: '500',
            ...(theme.typography.p2 as any),
            width: 'inherit',
            padding: '8px 24px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.p2 as any),
              padding: '8px 24px',
            },
          },
        },
        {
          props: { size: 'micro' },
          style: {
            fontWeight: '500',
            ...(theme.typography.p2 as any),
            width: 'inherit',
            padding: '8px 16px',

            [theme.breakpoints.down('sm')]: {
              ...(theme.typography.p2 as any),
              padding: '8px 16px',
            },
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },

          '& .MuiMenu-paper': {
            padding: '24px 0px',
            borderRadius: '24px',
          },

          '& .MuiMenuItem-root': {
            padding: '8px 24px',
            color: theme.palette.primary.main,
            ...(theme.typography.p1 as any),
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            padding: '16px',
            borderRadius: '24px',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-root': {
            marginTop: '4px',
            marginBottom: '4px',
          },

          '& .MuiPickersDay-root': {
            ...(theme.typography.c1 as any),
            color: theme.palette.gray8.main,
            border: `1px solid white`,
            transition: 'border-color 200ms ease',

            '&:hover': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: 'unset',
            },

            '&:focus': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: 'unset',
            },

            '&.Mui-selected': {
              backgroundColor: theme.palette.green1.main,
              color: theme.palette.primary.main,

              '&:hover': {
                backgroundColor: theme.palette.green1.main,
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },

              '&:focus': {
                backgroundColor: theme.palette.green1.main,
                color: theme.palette.primary.main,
              },
            },
          },

          '& .PrivatePickersYear-yearButton': {
            ...(theme.typography.c1 as any),
            color: theme.palette.gray8.main,
            border: `1px solid white`,
            transition: 'border-color 200ms ease',

            '&:hover': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: 'unset',
            },

            '&:focus': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: 'unset',
            },

            '&.Mui-selected': {
              backgroundColor: theme.palette.green1.main,
              color: theme.palette.primary.main,

              '&:hover': {
                backgroundColor: theme.palette.green1.main,
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },

              '&:focus': {
                backgroundColor: theme.palette.green1.main,
                color: theme.palette.primary.main,
              },
            },
          },

          '& .MuiDayPicker-weekDayLabel': {
            ...(theme.typography.c1 as any),
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'large',
        label: '',
        helperText: '',
        InputProps: {
          notched: false,
        },
      },
      styleOverrides: {
        root: {
          paddingTop: '24px',
          '& .MuiInputBase-root': {
            borderRadius: '48px',
            transition: 'background-color 200ms ease, box-shadow 200ms ease',
            backgroundColor: theme.palette.white.main,
            color: theme.palette.primary.main,

            '&:hover': {
              backgroundColor: theme.palette.gray1.main,
            },
          },
          '& .MuiInputBase-root.Mui-focused': {
            boxShadow: `0px 8px 24px rgba(0, 0, 0, 0.15)`,
            backgroundColor: theme.palette.white.main,
          },
          '& .MuiFormLabel-root': {
            transform: 'translate(32px, 48px) scale(1)',
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
            color: theme.palette.gray5.main,
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(32px, 0px) scale(1)',
            ...theme.typography.p3,
            color: theme.palette.primary.main,
          },
          '& .MuiInputBase-input': {
            padding: '24px 32px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            paddingLeft: '28px',
            paddingRight: '28px',
            border: `2px solid ${theme.palette.primary.main}`,
          },
          '& .MuiFormHelperText-root': {
            ...theme.typography.p3,
            marginLeft: '32px',
            marginRight: '32px',
            marginBottom: '0px',
            fontWeight: '300',
          },

          '& .MuiInputBase-adornedEnd': {
            paddingRight: '8px',
          },

          '& .Mui-error': {
            '& .MuiInputAdornment-root': {
              '& .MuiButtonBase-root': {
                backgroundColor: theme.palette.error.main,
              },
            },
          },
          '& .MuiSelect-icon': {
            top: 'calc(50% - 12px)',
            right: '24px',
          },
        },
      },
      variants: [
        {
          props: { label: '' },
          style: {
            paddingTop: '0px',
          },
        },
        {
          props: { multiline: true },
          style: {
            '& .MuiInputBase-input': {
              padding: '12.5px 10px',
              ...theme.typography.p1,
            },
          },
        },
        {
          props: { size: 'mini' },
          style: {
            '& .MuiInputBase-root': {
              borderRadius: '18px',
            },
            '& .MuiInputBase-input': {
              padding: '4px 16px',
              ...theme.typography.c1,
            },
            '& .MuiInputBase-inputMultiline': {
              padding: '0px',
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 36px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              ...theme.typography.p3,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.c1,
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.c1,
              marginLeft: '24px',
              marginRight: '24px',
              marginBottom: '0px',
              fontWeight: '300',
            },
            '& .MuiInputBase-adornedEnd': {
              paddingRight: '8px',
            },
          },
        },
        {
          props: { size: 'dropdownMini' },
          style: {
            '& .MuiInputBase-root': {
              '& .MuiSelect-icon': {
                right: '8px',
              },
              borderRadius: '36px',
              boxShadow: 'none',
            },
            '& .MuiInputBase-input': {
              padding: '6px 12px',
              ...theme.typography.p3,
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 36px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              ...theme.typography.p3,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.c1,
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.c1,
              marginLeft: '24px',
              marginRight: '12px',
              marginBottom: '0px',
            },
            '& .MuiInputBase-adornedEnd': {
              paddingRight: '0px',
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            '& .MuiInputBase-root': {
              borderRadius: '36px',
            },
            '& .MuiInputBase-input': {
              padding: '11px 24px 13px 24px',
              ...theme.typography.p1,
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 36px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              ...theme.typography.p1,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.p3,
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.p3,
              marginLeft: '24px',
              marginRight: '24px',
              marginBottom: '0px',
              fontWeight: '300',
            },
            '& .MuiInputBase-adornedEnd': {
              paddingRight: '8px',
            },
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            '& .MuiFilledInput-root': {
              backgroundColor: theme.palette.gray1.main,
              '&:hover': {
                backgroundColor: theme.palette.white.main,
              },
              '&::before': {
                borderBottom: 'none',
              },
              '&::after': {
                borderBottom: 'none',
              },
              '&:hover:not(.Mui-disabled)': {
                borderBottom: 'none',
                '&:before': {
                  borderBottom: 'none',
                },
                '&:after': {
                  borderBottom: 'none',
                },
              },
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(32px, 48px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              color: theme.palette.primary.main,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(32px, 0px) scale(1)',
              ...theme.typography.p2,
              color: theme.palette.white.main,
              fontWeight: 500,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.white.main,
            },
            '& .MuiInputBase-input': {
              padding: '24px 32px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '28px',
              paddingRight: '28px',
              border: `2px solid ${theme.palette.primary.main}`,
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.p3,
              marginLeft: '32px',
              marginRight: '32px',
              marginBottom: '0px',
              fontWeight: '300',

              color: theme.palette.white.main,
            },
          },
        },
        {
          props: { variant: 'filled', error: true },
          style: {
            '& .MuiInputLabel-root': {
              color: 'red!important',
            },
            '& .MuiFormHelperText-root': {
              color: 'red!important',
            },
          },
        },
        {
          props: { variant: 'filled', size: 'large' },
          style: {
            '& .MuiFormLabel-root': {
              transform: 'translate(32px, 48px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(32px, 0px) scale(1)',
              ...theme.typography.p2,
              fontWeight: 500,
            },
            '& .MuiInputBase-input': {
              padding: '24px 32px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '28px',
              paddingRight: '28px',
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.p3,
              marginLeft: '32px',
              marginRight: '32px',
              marginBottom: '0px',
              fontWeight: '300',
            },
          },
        },
        {
          props: { variant: 'filled', size: 'small' },
          style: {
            '& .MuiInputBase-input': {
              padding: '12px 24px',
              ...theme.typography.p2,
            },
            '& .MuiFormLabel-root': {
              transform: 'translate(24px, 35px) scale(1)',
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms ease',
              ...theme.typography.p2,
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(24px, 0px) scale(1)',
              ...theme.typography.p3,
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            '& .MuiFormHelperText-root': {
              ...theme.typography.p3,
              marginLeft: '24px',
              marginRight: '24px',
              marginBottom: '0px',
              fontWeight: '300',
            },
          },
        },
      ],
    },
    MuiAlert: {
      defaultProps: {
        icon: false,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
          borderRadius: '0px',
          paddingTop: 0,
          paddingBottom: 0,
          '& .MuiAlert-message': {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
          },
          '& .MuiAlert-action': {
            marginLeft: 'unset',
            alignItems: 'center',
            paddingTop: 0,
            [theme.breakpoints.down('sm')]: {
              paddingLeft: 0,
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.green1.main,
          color: theme.palette.primary.main,
          padding: '8px',
          gap: '10px',
          borderRadius: '0rem',

          '.MuiChip-label': {
            padding: 'unset',
            ...theme.typography.c1,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          transition: 'background-color 200ms linear, color 200ms linear',
          color: theme.palette.green4.main,
          cursor: 'pointer',
          width: 'fit-content',

          '&:hover': {
            color: theme.palette.green5.main,
          },

          '&:active': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiToggleButtonGroup-grouped': {
            '&:not(:first-of-type)': {
              borderLeft: `1px solid ${theme.palette.primary.main}`,
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.green0.main,
            '&:hover': {
              backgroundColor: theme.palette.green0.main,
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.white.main,
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        icon: false,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          borderRadius: '0rem',
          backgroundColor: 'unset',
          padding: '0px',
          '&:before': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          borderBottom: `3px solid ${theme.palette.gray8.main}`,
          ...theme.typography.h4,
          '& .MuiTypography-root': {
            ...theme.typography.p2,
            color: theme.palette.gray8.main,
            fontSize: '1.5rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.2rem',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          backgroundColor: 'white',
          paddingLeft: '72px',
          paddingRight: '72px',
          height: '80px',
          color: 'red',
          transition: 'background-color 500ms ease',

          [theme.breakpoints.down('md')]: {
            height: 'fit-content',
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderRightWidth: '1px',
          borderColor: theme.palette.gray8.main,
          width: '1px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.gray8.main,
            width: '8px',
            right: 'unset',
          },

          '& .MuiTypography-root': {
            color: theme.palette.primary.main,
            transition: 'font-size 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.green3.main,
            },
          },
          '&.Mui-disabled': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { boxShadow: theme.shadows[8] },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: { boxShadow: theme.shadows[8] },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          letterSpacing: 'inherit',
          lineHeight: 'inherit',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '36px',
          height: '20px',
          padding: '0px',
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
          },
          '& .MuiSwitch-switchBase': {
            padding: 0,
            color: theme.palette.primary.main,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: theme.palette.primary.main,
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.gray3.main,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            width: 20,
            height: 20,
            borderRadius: 32,
            transition: theme.transitions.create(['width'], {
              duration: 200,
            }),
          },
          '& .MuiSwitch-track': {
            borderRadius: 32,
            opacity: 1,
            backgroundColor: theme.palette.gray4.main,
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
})

const flockTheme = theme

export default flockTheme
