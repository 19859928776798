import { ThemeProvider } from '@mui/material'
import React from 'react'
import { flockTheme } from '../../theme'

type LibraryThemeProviderProps = {
  children: React.ReactNode
}

const LibraryThemeProvider = (props: LibraryThemeProviderProps) => {
  const { children } = props
  return <ThemeProvider theme={flockTheme}>{children}</ThemeProvider>
}

export default LibraryThemeProvider
